const namespaced = true

import api from '../../api/control-letter-details.js';
import _ from 'lodash';

const state = {
    control_letter_details : [],
}
const mutations = {
     MUTATION_SET_CONTROL_LETTER_DETAILS(state, control_letter_details) {
          state.control_letter_details = control_letter_details;
     },
     MUTATION_ADD_CONTROL_LETTER_DETAIL(state, controlLetterDetail){
          state.control_letter_details.push(controlLetterDetail);
     },
     MUTATION_UPDATE_CONTROL_LETTER_DETAIL(state, controlLetterDetailUpdate){
          let control_letter_details = state.control_letter_details;
          state.control_letter_details = [];
          _.forEach(control_letter_details, controlLetterDetail => {
               state.control_letter_details.push((controlLetterDetail.id_controlLetterDetail == controlLetterDetailUpdate.id_controlLetterDetail)?controlLetterDetailUpdate:controlLetterDetail);
          });
     },
     MUTATION_DELETE_CONTROL_LETTER_DETAIL(state, id_controlLetterDetail){
          let control_letter_details = state.control_letter_details;
          state.control_letter_details = [];
          _.forEach(control_letter_details, controlLetterDetail => {
               if(controlLetterDetail.id_controlLetterDetail != id_controlLetterDetail){
                    state.control_letter_details.push(controlLetterDetail);
               }
          });
     },

}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => {
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_CONTROL_LETTER_DETAILS', res.data.data);
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     show(context, id) {
          return new Promise((resolve, reject) => {
               api.show(id).then(function (res) {
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_CONTROL_LETTER_DETAIL', resp.data.data);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.data);
                }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
                });
          });
     },
     createSpecial(context, data){
        return new Promise((resolve, reject) => {
             api.createSpecial(data).then(resp => {
                //   context.commit('MUTATION_ADD_CONTROL_LETTER_DETAIL', resp.data.data);
                  context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                  resolve(resp.data.data);
              }).catch(error => {
                  context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                  reject(error);
              });
        });
   },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
            //    context.commit('MUTATION_UPDATE_CONTROL_LETTER_DETAIL', resp.data.data);
               context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                resolve(resp.data.data);
               }).catch(error => {
               context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
               reject(error);
               });
          });
     },
     delete(context, {id, data}){
          return new Promise((resolve, reject) => {
                api.deleted(id,data).then(resp => {
                    // context.commit('MUTATION_DELETE_CONTROL_LETTER_DETAIL', id);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
