<template>
    <v-app :style="{ background: (currentRoute != 'login') ? background : '' }">
        <v-main :class="[(currentRoute != 'login') ? 'main' : '']">

            <div class="row mb-3 mt-2">

                <navigation-menu v-if="currentRoute != 'login'" class="ml-6"></navigation-menu>


                <v-toolbar elevation="0" color="transparent">
                    <v-spacer></v-spacer>
                    <v-btn icon class="mr-2">
                        <v-img aspect-ratio="1.7" :src="'images/default_profile_picture.png'"></v-img>
                    </v-btn>
                </v-toolbar>

            </div>

            <router-view></router-view>

        </v-main>
        <v-snackbar bottom right outlined multi-line style="white-space: pre-line" v-model="notification.visibility"
            :color="notification.color" :timeout="notification.timeout">
            {{ notification.text }}
            <template v-slot:action="{ attrs }">
                <v-btn dark text v-bind="attrs" @click="notification.visibility = false" :color="notification.color">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import NavigationMenu from './shared/NavigationMenu.vue'


export default {
    name: 'App',
    components: {
        NavigationMenu,
    },
    data: () => ({}),
    methods: {
        ...mapActions('configuration', ['resetConfiguration']),
        ...mapActions('notifications', ['clear']),
        /**
         * Solo en el caso en que quisieramos ocultarlo en una determinada ruta.
         */
        isShow() {
            return (this.$route.fullPath != '/') ? true : false;
        }
    },
    computed: {
        ...mapState('notifications', ['notification']),
        ...mapState('authentication', ['isLoggedIn']),
        currentRoute() {
            return this.$route.name
        },
        background() {
            return this.$background;
        },
    },
    mounted() {
        if (this.isLoggedIn == false) {
            this.resetConfiguration();
        }
    },
    beforeMount() {
        this.clear();
    },
    watch: {
        isLoggedIn: function (nvl) {
            if (this.$route.fullPath != '/login') {
                nvl == false ? this.$router.push({ 'name': "login" }) : void (0);
            }
        },
    }
}
</script>

<style scoped></style>
