const namespaced = true

import api from '../../api/binnacles.js';

const state = {
    binnacles : [],
}
const mutations = {
     MUTATION_SET_ALL_BINNACLES(state, binnacles) {
          state.binnacles = binnacles;
     },
    
}
const actions = {
      // Name Property
      all(context) {
          return new Promise((resolve, reject) => { 
               api.all().then(function (res) {
                    context.commit('MUTATION_SET_ALL_BINNACLES', res.data.data);
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
