let prefix = '/api/procedure';

function index() {
    return window.axios.get(prefix+'/all');
}
function show(id) {
    return window.axios.get(prefix+'/one/'+id);
}
function store(data) {
    return window.axios.post(prefix+'/create', data)
}
function update(id, data) {
    return window.axios.post(prefix+'/update/'+id, data);
}
function deleted(id){
    return window.axios.delete(prefix+'/delete/'+id);
}
export default {
    index,
    show,
    store,
    update,
    deleted,
}
