import _ from 'lodash';
import router from '../../routes';
export default function setup() {
	window.axios.interceptors.response.use(config => {
		return config;
	}, error => {
		let messages = '';
		let status = error.response.status;
		let errors = error.response.data.error;
		if(status == 400) {
			messages = error.response.data.message;
		} else if(status == 401) {
			messages = 'Login again.';
		} else if(status == 403) {
			messages = 'You are not authorized to see some features.';
		} else {
			if (_.isString(errors)) {
				messages = errors;
			}else{
				_.forEach(errors, line => { messages += line + '\n'; });
			}
		}
		if(status == 401 && router.currentRoute.name != 'login'){
			router.push('/login');
		}
		return Promise.reject(messages)
	});
}