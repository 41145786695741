const namespaced = true

import api from '../../api/control-letters.js';
import _ from 'lodash';

const state = {
    control_letters : [],
}
const mutations = {
     MUTATION_SET_CONTROL_LETTERS(state, control_letters) {
          state.control_letters = control_letters;
     },
     MUTATION_ADD_CONTROL_LETTER(state, controlLetter){
          state.control_letters.push(controlLetter);
     },
     MUTATION_UPDATE_CONTROL_LETTER(state, controlLetterUpdate){
          let control_letters = state.control_letters;
          state.control_letters = [];
          _.forEach(control_letters, controlLetter => {
               state.control_letters.push((controlLetter.id_controlLetter == controlLetterUpdate.id_controlLetter)?controlLetterUpdate:controlLetter);
          });
     },
     MUTATION_DELETE_CONTROL_LETTER(state, id_controlLetter){
          let control_letters = state.control_letters;
          state.control_letters = [];
          _.forEach(control_letters, controlLetter => {
               if(controlLetter.id_controlLetter != id_controlLetter){
                    state.control_letters.push(controlLetter);
               }
          });
     },

}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => {
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_CONTROL_LETTERS', res.data.data);
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     show(context, id) {
          return new Promise((resolve, reject) => {
               api.show(id).then(function (res) {
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_CONTROL_LETTER', resp.data.data);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.data);
                }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
                });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
               context.commit('MUTATION_UPDATE_CONTROL_LETTER', resp.data.data);
               context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
               resolve(resp.data.data);
               }).catch(error => {
               context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
               reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
                api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_CONTROL_LETTER', id);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.code);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     masterKey(context, data){
        return new Promise((resolve, reject) => {
             api.masterKey(data).then(resp => {
                //   context.commit('MUTATION_ADD_CONTROL_LETTER', resp.data.data);
                  context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                  resolve(resp.data.data);
              }).catch(error => {
                  context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                  reject(error);
              });
        });
   },
}

const getters = {

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
