const namespaced = true;

import api from "../../api/bilbiography.js";
import _ from "lodash";

const state = {

    typeBibliography: [
        "ACADEMICO",
        "NORMATIVO",
        "PERIODICO",
        "LIBRO",
        "CAPITULOLIBRO",
        "ARTICULOREVISTA",
        "TESIS",
        "DISERTACION",
        "DOCUMENTOINSTITUCIONAL",
        "SITIOWEB",
        "BASEDATOS",
        "RECURSOMULTIMEDIA",
        "INFORMETECNICO",
        "DOCUMENTOPOLITICA",
        "TRABAJOCONFERENCIA",
        "RECURSODIGITAL",
        "PUBLICACIONESPECIALIZADA",
        "GUIA",
        "MANUAL",
        "REPORTAJE",
        "PODCAST",
        "VIDEO",
        "AUDIO",
        "BLOG",
        "FORO",
        "WIKI",
        "REDSOCIAL",
        "NOTICIA",
        "ENTREVISTA",
        "DOCUMENTAL",
        "SERIETELEVISIVA",
        "PELICULA",
        "MUSICA",
        "SOFTWARE",
        "PATENTE",
        "NORMATÉCNICA",
        "NORMALEGAL",
        "ESTANDARINDUSTRIAL",
        "ESTANDAREDUCATIVO",
        "ESTANDARSALUD",
        "ESTANDARAMBIENTAL",
        "ESTANDARCULTURAL",
        "ESTANDARSOCIAL",
        "ESTANDARECONÓMICO",
        "ESTANDARTECNOLÓGICO",
        "ESTANDARCIENTÍFICO",
        "ESTANDARARQUITECTÓNICO",
        "ESTANDARURBANÍSTICO",
        "ESTANDARGEOGRÁFICO",
        "ESTANDARHISTÓRICO",
        "ESTANDARFILOLÓGICO",
        "ESTANDARLINGÜÍSTICO",
        "ESTANDARLITERARIO",
        "ESTANDARARTÍSTICO",
        "ESTANDARCULINARIO",
        "ESTANDARDEPORTIVO",
        "ESTANDARDEVIAJE",
        "ESTANDARDEMODA",
        "ESTANDARDEBELLEZA",
        "ESTANDARDESALUD",
        "ESTANDARDEEDUCACIÓN",
        "ESTANDARDETRABAJO",
        "ESTANDARDECOMERCIO",
        "ESTANDARDEINDUSTRIA",
        "ESTANDARDESERVICIOS",
        "ESTANDARDETRANSPORTE",
        "ESTANDARDETURISMO",
        "ESTANDARDECIENCIA",
        "ESTANDARDETECNOLOGÍA",
        "ESTANDARDEINNOVACIÓN",
        "ESTANDARDEINVESTIGACIÓN",
        "ESTANDARDEDESARROLLO",
        "ESTANDARDEPROGRESO",
        "ESTANDARDECALIDAD",
    ],
};
const mutations = {};
const actions = {
    store(context, data) {
        return new Promise((resolve, reject) => {
            api.store(data)
                .then((resp) => {
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: resp.data.message,
                        },
                        { root: true }
                    );
                    resolve(resp.data.data);
                })
                .catch((error) => {
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: error,
                        },
                        { root: true }
                    );
                    reject(error);
                });
        });
    },
    delete(context, id){
        return new Promise((resolve, reject) => {
              api.deleted(id).then(resp => {
                  context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                  resolve(resp.data.code);
             }).catch(error => {
                  context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                  reject(error);
             });
        });
   },
   update(context, {id, data}){
    return new Promise((resolve, reject) => {
         api.update(id, data).then(resp => {
         context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
         resolve(resp.data.data);
         }).catch(error => {
         context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
         reject(error);
         });
    });
},
};

const getters = {};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
};
