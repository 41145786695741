import _, { forEach } from 'lodash';

const namespaced = true

const state = {
    graphic: [],
    names:[],
    namesSelect:[],
    controlLetter: [],

}

const mutations = {
    MUTATION_SET_INDEX(state, {data}) {
        state.graphic = [];
        state.graphic = data;
    },
    MUTATION_SET_ONE(state, {position,data}) {
        state.graphic[position] = data;
    },

    /**
     * MUTATION para obtener todos los primeros nombres
     * @param data es un arreglo de arreglos con todos los nombres de todas las tablas
     * @returns state.names: contiene todos los nombres de las tablas, state.namesSelect: contiene los nomrbes de las primeras tablas
     */
    MUTATION_SET_INDEX_NAMES(state, {data}) {
        state.names = [];
        state.names = data;
        _.forEach(data, table => {
            state.namesSelect.push(table[0]);
       });
    },



    /**
     * MUTATION para cambiar el nombre de las tablas en la grafica dependiendo de la que esta seleccionada
     * @param position carta control en la que esta
     * @param positionTable posicion de la tabla en la carta control
     * @returns state.namesSelect con los nuevos nombres
     * Detalle
     * Si es null actualiza el nombre
     */
    MUTATION_SET_ONE_NAME(state,{position,positionTable}){
        let data = state.names[position][positionTable];
        if(data.name_table == null){
            data.name_table='Actualizar nombre '+(positionTable+1);
        }
        state.namesSelect[position]=data;
    },

    /**
     *
     * @param idControlLetteTable id de la tabla
     * @param name nombre de la tabla
     */
    MUTATION_UPATE_ONE_NAME(state,{idControlLetteTable,name,newData}){

        //Actualizar mis nombre de mis datos
        state.names = [];
        _.forEach(newData, data =>{
            state.names.push(data.names);
        })

        let indice1= 0;
        let realIndice = 0;
        //Obtengo la posicion del name select
        _.forEach(state.names, data => {
            _.forEach(data, item => {
                if(item['id_control_letter_table']==idControlLetteTable){
                    realIndice =indice1;
                }
            })
            indice1 +=1;
       });
    //    console.log("BEFORE");
    //    console.log(state.namesSelect);
       state.namesSelect.forEach((data, posicion) => {
        if (posicion === realIndice) {
            state.namesSelect[posicion].name_table=name;
        }
        // console.log("AFTER");
        // console.log(state.namesSelect);

    });
    },

    /**
     * MUTATION para argegar una nueva fila
     * @param id el id de la tabla de la carta control
     * @param row la fila a agregar
     **** Se accede a la posicion cero ya que solo me interesa saber el id de la tabla de la carta control
     */
    MUTATION_ADD_ROW_TABLE(state,{id,row}){
        let indice1= 0;
        console.log("AFTER");
        console.log(state.graphic);
        console.log("MUTATION_ADD_ROW_TABLE");
        _.forEach(state.graphic, data => {
            if(data[0]['id_control_letter_table'] == id){
                state.graphic[indice1].push(row);
            }
            indice1 +=1;
       });
       console.log("BEFORE");
       console.log(state.graphic);
    },


    /**
     * MUTATION para argegar una nueva fila
     * @param id el id de la fila de la tabla de la carta (id_control_letter_details)
     * @param row toda la fila se actualiza
     **** Se accede a la posicion cero ya que solo me interesa saber el id de la tabla de la carta control
     */
    MUTATION_UPDATE_ROW_TABLE(state,{id,row}){
        let indice1= 0;
        _.forEach(state.graphic, data => {
            let indice2= 0;
            _.forEach(data, item => {
                if(item['id_control_letter_details']==id){
                    state.names[indice1][indice2]=row;
                }
                indice2 +=1;
            })
            indice1 +=1;
       });
    },


    /**
     * MUTATION PARA DUPLICAR LA TABLA
     * Se duplcia el nombre
     * @param id ID de la carta control
     */
    MUTATION_DUPLICATE_TABLE(state,{id}){
        let posicionReal= 0;
        state.controlLetter.forEach((data, posicion) => {
            if (data === id) {
                posicionReal = posicion
            }
            posicion +=1;
        });

        let array= {
            id_control_letter_table:0,
            name_table : null
        }
        state.names.forEach((data, posicion) => {
            if(posicion == posicionReal){
                state.names[posicion].push(array);
            }
        });




        // state.graphic.forEach((data, posicion) => {
        //     console.log(posicion)
        //     if(posicionReal == posicion){
        //         state.graphic[posicionReal]
        //         state.graphic.push(state.graphic[posicionReal]);
        //     }

        // });

    },


    MUTATION_INDEX_CONTROL_LETTER(state, {data}) {
        state.controlLetter = [];
        state.controlLetter = data;
    },
}

const actions = {
    async index(context,{data}){
        context.commit('MUTATION_SET_INDEX', {data});
    },
    async addLine({state, commit, rootState},{position,data}){
        await commit('MUTATION_SET_ONE', { position,data});
    },

    indexNames(context,{data}){
        context.commit('MUTATION_SET_INDEX_NAMES',{data});
    },

    updateName(context,{idControlLetteTable,name,newData}){
        context.commit('MUTATION_UPATE_ONE_NAME',{idControlLetteTable,name,newData});
    },


    updateNamePro(context,{position,positionTable}){
        context.commit('MUTATION_SET_ONE_NAME',{position,positionTable});
    },

    addRowTable(context,{id,row}){
        context.commit('MUTATION_ADD_ROW_TABLE',{id,row});
    },

    updateRowTable(context,{id,row}){
        context.commit('MUTATION_UPDATE_ROW_TABLE',{id,row});
    },

    deleteRowTable(context,{id,row}){
        context.commit('MUTATION_DELETE_ROW_TABLE',{id,row});
    },

    duplicateTable(context,{id}){
        context.commit('MUTATION_DUPLICATE_TABLE',{id});
    },

    indexControlLetter(context,{data}){
        context.commit('MUTATION_INDEX_CONTROL_LETTER',{data});
    },


}

const getters = {
    getGraphic(state) {
        return state.graphic;
    }
}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
