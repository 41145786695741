const namespaced = true

import api from '../../api/characteristics.js';
import _ from 'lodash';

const state = {
    characteristics : [],
}
const mutations = {
     MUTATION_SET_CHARACTERISTICS(state, characteristics) {
          state.characteristics = characteristics;
     },
     MUTATION_ADD_CHARACTERISTIC(state, characteristic){
          characteristic.name = characteristic.name_characteristic;
          characteristic.equipments = [];
          state.characteristics.push(characteristic);
     },
     MUTATION_UPDATE_CHARACTERISTIC(state, characteristicUpdate){
          let characteristics = state.characteristics;
          state.characteristics = [];
          _.forEach(characteristics, characteristic => {
               state.characteristics.push((characteristic.id_characteristic == characteristicUpdate.id_characteristic)?characteristicUpdate:characteristic);
          });
     },
     MUTATION_DELETE_CHARACTERISTIC(state, id_characteristic){
          let characteristics = state.characteristics;
          state.characteristics = [];
          _.forEach(characteristics, characteristic => {
               if(characteristic.id_characteristic != id_characteristic){
                    state.characteristics.push(characteristic);
               }
          });
     },
     MUTATION_ADD_EQUIPMENT(state, {id_characteristic, equipment}){
          let characteristics = [];
          _.forEach(state.characteristics, characteristic => {
               if(characteristic.id_characteristic == id_characteristic){
                    characteristic.equipments.push({equipment: equipment, id_characteristic: characteristic.id_characteristic});
               }
               characteristics.push(characteristic);
          });
          state.characteristics = characteristics;
     },

}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => {
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_CHARACTERISTICS', res.data.data);
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     show(context, id) {
          return new Promise((resolve, reject) => {
               api.show(id).then(function (res) {
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_CHARACTERISTIC', resp.data.data);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.data);
                }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
                });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
               context.commit('MUTATION_UPDATE_CHARACTERISTIC', resp.data.data);
               context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
               resolve(resp.data.data);
               }).catch(error => {
               context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
               reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
                api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_CHARACTERISTIC', id);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.code);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     addDetail(context, {id_characteristic, equipment}){
          return new Promise((resolve, reject) => {
                api.addDetail(id_characteristic, equipment.id_equipment).then(resp => {
                    context.commit('MUTATION_ADD_EQUIPMENT', {id_characteristic: id_characteristic, equipment: equipment});
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.code);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
