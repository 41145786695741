import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary:    "#42A5F5",
                secondary:  "#ff6400",
                accent:     "#ff5722",
                error:      "#f44336",
                warning:    "#ff9800",
                info:       "#2196f3",
                success:    "#4caf50",
                selected :  "#C8E6C9",
                metrology:  "#3B648D",
                edit: "#FEC82F",
                buttonSpecial: "#455A64",
                traceability: "#90A4AE",
                columnWidths: {
                    medium: '4rem',
                },
                columnTablesChips: {
                    medium: '25rem',
                },
            },
            dark: {
                primary:    '#0D47A1',
                secondary:  "#c43100",
                accent:     "#BF360C",
                error:      "#B71C1C",
                warning:    "#E65100",
                info:       "#0D47A1",
                success:    "#1B5E20",
                selected :  "#1B5E20",
                metrology:  "#3B648D",
                edit: "#FEC82F",
                buttonSpecial: "#455A64",
                traceability: "#90A4AE",
                columnWidths: {
                    medium: '4rem',
                },
                columnTablesChips: {
                    medium: '25rem',
                },
            },
        },
    },
}

export default new Vuetify(opts)
