let prefix = '/api/product';

function all() {
    return window.axios.get('/api/start/allProducts');
}
function index() {
    return window.axios.get(prefix+'/all');
}
function show(id) {
    return window.axios.get(prefix+'/one/'+id);
}
function store(data) {
    return window.axios.post(prefix+'/create', data)
}
function update(id, data) {
    return window.axios.post(prefix+'/update/'+id, data);
}
function deleted(id){
    return window.axios.delete(prefix+'/delete/'+id);
}
function addDetail(id_product, id_characteristic){
    return window.axios.post('/api/productDatail/create', {id_product: id_product, id_characteristic: id_characteristic});
}

function deleteDetail(id_product, id_characteristic){
    const body={id_product, id_characteristic}
    return window.axios.delete('/api/productDatail/delete',{ data: body });
}

export default {
    all,
    index,
    show,
    store,
    update,
    deleted,
    addDetail,
    deleteDetail,
}
