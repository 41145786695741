<template>
    <v-container>
        <v-row class="mr-5">
            <v-col cols="2" sm="1" md="1" lg="1" xl="1">
                <v-btn outlined @click.stop="drawerConf = !drawerConf">
                    <span class="mdi mdi-menu"></span>
                </v-btn>
            </v-col>
            <v-col cols="10" sm="10" md="10" lg="10" xl="10">
                <span class="subtitle-2 mx-auto font-weight-bold text-truncate">{{ $route.meta.title }} </span>
            </v-col>
        </v-row>

        <v-navigation-drawer :style="{ background }" v-model="drawerConf" fixed width="20%">
            <template v-slot:prepend>
                <v-list-item class="px-4 pt-1">
                    <v-list-item-title>Menú</v-list-item-title>
                    <v-btn icon @click.stop="drawerConf = !drawerConf">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item>
            </template>
            <v-divider></v-divider>

            <div class="mt-5">
                <!-- <v-col class="pa-1 ma-1 " cols="12">
                    <v-img src="images/general_localibro.png" alt="Metrology" width="70%"></v-img>
                    </v-col> -->
                    <v-col class="pa-1 ma-1" :cols="9" offset="9">
                        <v-img src="images/general-localibro.png" alt="Metrology" width="70%" class="ml-5"></v-img>
                    </v-col>
                    <v-col class="pl-0 pt-1" cols="12">
                        <v-img src="images/navegation-cuenta-muestra.png" alt="Llantas" width="100%"></v-img>
                    </v-col>
                    <v-col class="pt-0 pl-0" cols="12">
                        <v-list shaped :style="{ background }">
                            <v-list-item v-for="item in items" :key="item.title" @click="goTo(item.goTo)"
                                :class="{ 'grey lighten-1': currentRoute == item.goTo }" link dense>
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col class="pt-0 pl-0 align-end" cols="12">
                        <v-list shaped :style="{ background }">
                            <v-list-item @click="logout" link dense>
                                <v-list-item-icon>
                                    <v-icon>mdi-logout</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>Cerrar Sesión</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
            </div>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
    import default_profile_picture from '../users/images/default_profile_picture.png';

    import { mapState, mapActions } from 'vuex';

    export default {
        props: ['config'],
        data: () => ({
            group: null,
            drawerConf: false,
            items: [
                { title: 'Dashboard', goTo: 'dashboard', icon: 'mdi-home' },
                { title: 'Productos', goTo: 'products', icon: 'mdi-engine' },
                { title: 'Catálogo de Características', goTo: 'characteristics', icon: 'mdi-ruler' },
                { title: 'Equipos de Medición', goTo: 'equipments', icon: 'mdi-ruler-square' },
                { title: 'Reportes', goTo: 'reports', icon: 'mdi-file-chart' },
            ],
            mini: false,
        }),
        components: {

        },
        computed: {
            ...mapState('authentication', ['userAuth']),
            ...mapState('configuration', ['themeDark']),
            currentRoute() {
                return this.$route.name
            },
            background() {
                return this.$background;
            },
            logged() {
                return (this.$route.name !== "home")
            },
            sideMenu() {
                return this.$vuetify.breakpoint.width <= 1264 && this.logged
            },
            get_is_dark: {
                get: function () {
                    return this.themeDark;
                },
                set: function (newValue) {
                    return newValue;
                }
            }
        },
        methods: {
            ...mapActions('configuration', ['setDarkMode']),
            logout() {
                this.$router.push({ name: "logout" });
            },
            goTo(name) {
                this.$router.push({ name: name }).catch(error => {
                    if (error.name != "NavigationDuplicated") {
                        throw error;
                    }
                });
            },
            showhere(route) {
                return (this.$route.fullPath != route) ? true : false;
            },
            checkImage(image) {
                if (image == 'default_user_image.jpg' || image == 'default_profile_picture.png' || image == null) {
                    return default_profile_picture;
                } else {
                    return image;
                }
            }
        },
        watch: {
            get_is_dark: function (val) {
                this.$vuetify.theme.dark = val;
            }
        },
        mounted() {
            this.$vuetify.theme.dark = this.get_is_dark;
        }
    }
</script>

<style>
.router-link-exact-active {
    background-color: rgba(0, 0, 0, .1);
    border-radius: 5px;
    color: #1867c0 !important;
    caret-color: #1867c0 !important;
    font-style: italic;
    transition: .3s cubic-bezier(.25, .8, .5, 1);
}
</style>

<style scoped>

.align-end {
    position: absolute;
    bottom: 0%;
}
</style>
