let prefix = '/api/characteristic';

function index() {
    return window.axios.get(prefix+'/all');
}
function show(id) {
    return window.axios.get(prefix+'/one/'+id);
}
function store(data) {
    return window.axios.post(prefix+'/create', data);
}
function update(id, data) {
    return window.axios.patch(prefix+'/update/'+id, data);
}
function deleted(id){
    return window.axios.delete(prefix+'/delete/'+id);
}
function addDetail(id_characteristic, id_equipment){
    return window.axios.post('/api/characteristicDatail/create', {id_characteristic: id_characteristic, id_equipment: id_equipment});
}
export default {
    index,
    show,
    store,
    update,
    deleted,
    addDetail,
}
