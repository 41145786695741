import Vue from 'vue';  
import Router from 'vue-router'

Vue.use(Router);

const routerOptions = [
  {
    path: '/',
    name: 'public',
  },
  {
    path: '/login',
    name: 'login',
  },
  {
    path: '/logout',
    name: 'logout',
  },
  {
    path: '/register',
    name: 'register',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
  },
  {
    path: '/products',
    name: 'products',
  },
  {
    path: '/equipments-card',
    name: 'equipments/card',
  },
  {
    path: '/equipments',
    name: 'equipments',
  },
  {
    path: '/characteristics',
    name: 'characteristics',
  },
  {
    path: '/reports',
    name: 'reports',
  },
]

// Rutas
const routes = routerOptions.map(r => {
  return {
    ...r,
    component: () => import(`../components/${r.name}/index-component.vue`)
  }
})

const router = new Router({
  mode: 'history',
  base: __dirname,
  routes
})

export default router