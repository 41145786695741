
function login(data){
    return window.axios.post('/api/auth/login', data);
}
function loginByEmail(data){
    return window.axios.post('/api/email/login', data);
}
function registerByGoogle(data){
    return window.axios.post('/api/google/register', data);
}
function registerByEmail(data){
    return window.axios.post('/api/email/register', data);
}
function logout(){
    return window.axios.get('/api/auth/logout');
}

export default {
    login,registerByGoogle,registerByEmail,loginByEmail,logout
}