const namespaced = true

import api from '../../api/traceabilities.js';
import _ from 'lodash';

const state = {
    traceabilities : [],
}
const mutations = {
     MUTATION_SET_TRACEABILITIES(state, traceabilities) {
          state.traceabilities = traceabilities;
     },
     MUTATION_ADD_TRACEABILITY(state, traceability){
          state.traceabilities.push(traceability);
     },
     MUTATION_UPDATE_TRACEABILITY(state, traceabilityUpdate){
          let traceabilities = state.traceabilities;
          state.traceabilities = [];
          _.forEach(traceabilities, traceability => {
               state.traceabilities.push((traceability.id_traceability == traceabilityUpdate.id_traceability)?traceabilityUpdate:traceability);
          });
     },
     MUTATION_DELETE_TRACEABILIT(state, id_traceability){
          let traceabilities = state.traceabilities;
          state.traceabilities = [];
          _.forEach(traceabilities, traceability => {
               if(traceability.id_traceability != id_traceability){
                    state.traceabilities.push(traceability);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_TRACEABILITIES', res.data.data);
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     show(context, id) {
          return new Promise((resolve, reject) => { 
               api.show(id).then(function (res) {
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_TRACEABILITY', resp.data.data);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.data);
                }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
                });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
               context.commit('MUTATION_UPDATE_TRACEABILITY', resp.data.data);
               context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
               resolve(resp.data.data);
               }).catch(error => {
               context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
               reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
                api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_TRACEABILIT', id);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.code);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
