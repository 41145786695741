let prefix = '/api/controlLetterDetail';

function index() {
    return window.axios.get(prefix + '/all');
}
function show(id) {
    return window.axios.get(prefix + '/one/' + id);
}
function store(data) {
    return window.axios.post(prefix + '/create', data)
}
function createSpecial(data) {
    return window.axios.post(prefix + '/createSpecial', data)
}
function update(id, data) {
    return window.axios.patch(prefix + '/update/' + id, data);
}
function deleted(id, data) {
    return window.axios.post(prefix + '/delete/' + id, data);
}
export default {
    index,
    show,
    store,
    update,
    deleted,
    createSpecial,
}
