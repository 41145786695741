const namespaced = true

import api from '../../api/reference-value-details.js';
import _ from 'lodash';

const state = {
    reference_value_details : [],
}
const mutations = {
     MUTATION_SET_REFERENCE_VALUE_DETAILS(state, reference_value_details) {
          state.reference_value_details = reference_value_details;
     },
     MUTATION_ADD_REFERENCE_VALUE_DETAIL(state, referenceValueDetail){
          state.reference_value_details.push(referenceValueDetail);
     },
     MUTATION_UPDATE_REFERENCE_VALUE_DETAIL(state, referenceValueDetailUpdate){
          let reference_value_details = state.reference_value_details;
          state.reference_value_details = [];
          _.forEach(reference_value_details, referenceValueDetail => {
               state.reference_value_details.push((referenceValueDetail.id_referenceValueDetail == referenceValueDetailUpdate.id_referenceValueDetail)?referenceValueDetailUpdate:referenceValueDetail);
          });
     },
     MUTATION_DELETE_REFERENCE_VALUE_DETAIL(state, id_referenceValueDetail){
          let reference_value_details = state.reference_value_details;
          state.reference_value_details = [];
          _.forEach(reference_value_details, referenceValueDetail => {
               if(referenceValueDetail.id_referenceValueDetail != id_referenceValueDetail){
                    state.reference_value_details.push(referenceValueDetail);
               }
          });
     },

}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => {
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_REFERENCE_VALUE_DETAILS', res.data.data);
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     show(context, id) {
          return new Promise((resolve, reject) => {
               api.show(id).then(function (res) {
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_REFERENCE_VALUE_DETAIL', resp.data.data);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.data);
                }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
                });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
               context.commit('MUTATION_UPDATE_REFERENCE_VALUE_DETAIL', resp.data.data);
               context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
               resolve(resp.data.data);
               }).catch(error => {
               context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
               reject(error);
               });
          });
     },
     delete(context, {id, data}){
          return new Promise((resolve, reject) => {
                api.deleted(id, data).then(resp => {
                    // context.commit('MUTATION_DELETE_REFERENCE_VALUE_DETAIL', resp.data.data);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
