const namespaced = true

import api from '../../api/accuracy';


const state = {
    accuracy : [],
}

const mutations = {
     MUTATION_SET_ACCURACY(state, accuracy) {
          state.accuracy = accuracy;
     },
}

const actions = {
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_ACCURACY', res.data.data);
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
}

const getters = {    
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
