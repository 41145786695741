const namespaced = true

import api from '../../api/control-letter-tables.js';
import _ from 'lodash';

const state = {
    control_letter_tables : [],
}
const mutations = {
     MUTATION_SET_CONTROL_LETTER_TABLES(state, control_letter_tables) {
          state.control_letter_tables = control_letter_tables;
     },
     MUTATION_ADD_CONTROL_LETTER_TABLE(state, controlLetterTable){
          state.control_letter_tables.push(controlLetterTable);
     },
     MUTATION_UPDATE_CONTROL_LETTER_TABLE(state, controlLetterTableUpdate){
          let control_letter_tables = state.control_letter_tables;
          state.control_letter_tables = [];
          _.forEach(control_letter_tables, controlLetterTable => {
               state.control_letter_tables.push((controlLetterTable.id_controlLetterTable == controlLetterTableUpdate.id_controlLetterTable)?controlLetterTableUpdate:controlLetterTable);
          });
     },
     MUTATION_DELETE_CONTROL_LETTER_TABLE(state, id_controlLetterTable){
          let control_letter_tables = state.control_letter_tables;
          state.control_letter_tables = [];
          _.forEach(control_letter_tables, controlLetterTable => {
               if(controlLetterTable.id_controlLetterTable != id_controlLetterTable){
                    state.control_letter_tables.push(controlLetterTable);
               }
          });
     },

}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => {
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_CONTROL_LETTER_TABLES', res.data.data);
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     show(context, id) {
          return new Promise((resolve, reject) => {
               api.show(id).then(function (res) {
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_CONTROL_LETTER_TABLE', resp.data.data);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.data);
                }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
                });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
            //context.commit('MUTATION_UPDATE_CONTROL_LETTER_TABLE', resp.data.data);
               context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
               resolve(resp.data.data);
               }).catch(error => {
               context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
               reject(error);
               });
          });
     },
     updateMaster(context, {id, data}){
        return new Promise((resolve, reject) => {
            console.log(id);
             api.updateMaster(id, data).then(resp => {
             //context.commit('MUTATION_UPDATE_CONTROL_LETTER_TABLE', resp.data.data);
             context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
             resolve(resp.data.data);
             }).catch(error => {
             context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
             reject(error);
             });
        });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
                api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_CONTROL_LETTER_TABLE', id);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.code);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
