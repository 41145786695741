const namespaced = true;

import api from "../../api/equipments.js";
import _ from "lodash";

const state = {
    equipments: [],
    allEquipments: [],
    equipment: {
        traceabilities: [],
    },
    equipmentShow: {
        traceabilities: [],
    },
    unitMeasurement: [
        "mm",
        "cm",
        "dm",
        "m",
        "km",
        "g",
        "kg",
        "t",
        "L",
        "mL",
        "cm³",
        "m³",
        "K",
        "°C",
        "°F",
        "Pa",
        "bar",
        "atm",
        "J",
        "W",
        "A",
        "V",
        "Ω",
        "S",
        "F",
        "Hz",
        "N",
        "Wb",
        "T",
        "C",
        "rad",
        "sr",
        "H",
        "lm",
        "lx",
        "Bq",
        "Gy",
        "Sy",
        "kat",
        "ha"
    ]
};
const mutations = {
    MUTATION_SET_ALL_EQUIPMENTS(state, equipments) {
        state.allEquipments = equipments;
    },
    MUTATION_SET_EQUIPMENTS(state, equipments) {
        state.equipments = equipments;
    },
    MUTATION_ADD_EQUIPMENT(state, equipment) {
        state.equipments.push(equipment);
    },
    MUTATION_UPDATE_EQUIPMENT(state, equipmentUpdate) {
        let equipments = state.equipments;
        state.equipments = [];
        _.forEach(equipments, (equipment) => {
            state.equipments.push(
                equipment.id_equipment == equipmentUpdate.id_equipment
                    ? equipmentUpdate
                    : equipment
            );
        });
    },
    MUTATION_DELETE_EQUIPMENT(state, id_equipment) {
        let equipments = state.equipments;
        state.equipments = [];
        _.forEach(equipments, (equipment) => {
            if (equipment.id_equipment != id_equipment) {
                state.equipments.push(equipment);
            }
        });
    },
    MUTATION_SET_EQUIPMENT(state, equipment) {
        state.equipment = equipment;
    },
    MUTATION_ADD_PHOTO(state, { photo, photo_type }) {
        if (photo_type == 1) {
            state.equipment.photos_team.push(photo);
        }
        if (photo_type == 2) {
            state.equipment.photos_case.push(photo);
        }
    },
    MUTATION_ADD_MANUAL(state, { manual, manual_type }) {
        if (manual_type == 1) {
            state.equipment.manual_user.push(manual);
        }
        if (manual_type == 2) {
            state.equipment.manual_team.push(manual);
        }
    },
    MUTATION_ADD_PROCEDURE(state, { procedure, procedure_type }) {
        if (procedure_type == 1) {
            state.equipment.procedure_user.push(procedure);
        }
        if (procedure_type == 2) {
            state.equipment.procedure_caliobration.push(procedure);
        }
    },

    MUTATION_ADD_CERTIFICATE(state, certificate) {
        state.equipment.certificate.push(certificate);
    },

    MUTATION_ADD_REFERENCE(state, reference) {
        state.equipment = reference;
        state.equipmentShow = reference;
    },

    MUTATION_ADD_CONTROL_LETTER(state, controlLetter) {
        state.equipment.controls_letters.push(controlLetter);
    },
    MUTATION_ADD_TRACEABILITY(state, traceability) {
        state.equipment.traceabilities.push(traceability);
    },
    MUTATION_UPDATE_MANUAL(state, { manual, manual_type }) {
        if (manual_type == 1) {
            let manuals = _.filter(state.equipment.manual_user, (man) => {
                return man.id_manual != manual.id_manual;
            });
            state.equipment.manual_user = manuals;
            state.equipment.manual_user.push(manual);
        }
        if (manual_type == 2) {
            let manuals = _.filter(state.equipment.manual_team, (man) => {
                return man.id_manual != manual.id_manual;
            });
            state.equipment.manual_team = manuals;
            state.equipment.manual_team.push(manual);
        }
    },
    MUTATION_UPDATE_PROCEDURE(state, { procedure, procedure_type }) {
        if (procedure_type == 1) {
            let procedures = _.filter(
                state.equipment.procedure_user,
                (proce) => {
                    return proce.id_procedure != procedure.id_procedure;
                }
            );
            state.equipment.procedure_user = procedures;
            state.equipment.procedure_user.push(procedure);
        }
        if (procedure_type == 2) {
            let procedures = _.filter(
                state.equipment.procedure_caliobration,
                (proce) => {
                    return proce.id_procedure != procedure.id_procedure;
                }
            );
            state.equipment.procedure_caliobration = procedures;
            state.equipment.procedure_caliobration.push(procedure);
        }
    },
    MUTATION_UPDATE_CERTIFICATE(state, certificate) {
        state.equipment.certificate = _.filter(
            state.equipment.certificate,
            (certifi) => {
                return certifi.id_certificate != certificate.id_certificate;
            }
        );
        state.equipment.certificate.push(certificate);
    },
    MUTATION_UPDATE_TRACEABILITY(state, traceability) {
        state.equipment.traceabilities = _.filter(
            state.equipment.traceabilities,
            (trace) => {
                return trace.id_traceability != traceability.id_traceability;
            }
        );
        state.equipment.traceabilities.push(traceability);
    },
    MUTATION_DELETE_PHOTO(state, { id_photo, photo_type }) {
        if (photo_type == 1) {
            let photos = _.filter(state.equipment.photos_team, (photo) => {
                return photo.id_photo != id_photo;
            });
            state.equipment.photos_team = photos;
        }
        if (photo_type == 2) {
            let photos = _.filter(state.equipment.photos_case, (photo) => {
                return photo.id_photo != id_photo;
            });
            state.equipment.photos_case = photos;
        }
    },
    MUTATION_DELETE_MANUAL(state, { id_manual, manual_type }) {
        if (manual_type == 1) {
            let manuals = _.filter(state.equipment.manual_user, (manual) => {
                return manual.id_manual != id_manual;
            });
            state.equipment.manual_user = manuals;
        }
        if (manual_type == 2) {
            let manuals = _.filter(state.equipment.manual_team, (manual) => {
                return manual.id_manual != id_manual;
            });
            state.equipment.manual_team = manuals;
        }
    },
    MUTATION_DELETE_PROCEDURE(state, { id_procedure, procedure_type }) {
        if (procedure_type == 1) {
            let procedures = _.filter(
                state.equipment.procedure_user,
                (procedure) => {
                    return procedure.id_procedure != id_procedure;
                }
            );
            state.equipment.procedure_user = procedures;
        }
        if (procedure_type == 2) {
            let procedures = _.filter(
                state.equipment.procedure_caliobration,
                (procedure) => {
                    return procedure.id_procedure != id_procedure;
                }
            );
            state.equipment.procedure_caliobration = procedures;
        }
    },
    MUTATION_DELETE_CERTIFICATE(state, id_certificate) {
        let certificates = _.filter(
            state.equipment.certificate,
            (certificate) => {
                return certificate.id_certificate != id_certificate;
            }
        );
        state.equipment.certificate = certificates;
    },
    MUTATION_DELETE_TRACEABILITY(state, id_traceability) {
        let traceabilities = _.filter(
            state.equipment.traceabilities,
            (trace) => {
                return trace.id_traceability != id_traceability;
            }
        );
        state.equipment.traceabilities = traceabilities;
    },
    MUTATION_ADD_BIBLIOGRAPHY(state, bibliography) {
        state.equipment.bibliography.push(bibliography);
    },
    MUTATION_DELETE_BIBLIOGRAPHY(state, id_bibliographic) {
        let bibliographys = _.filter(
            state.equipment.bibliography,
            (bibliography) => {
                return bibliography.id_bibliographic != id_bibliographic;
            }
        );
        state.equipment.bibliography = bibliographys;
    },
    MUTATION_UPDATE_BIBLIOGRAPHY(state, bibliography) {
        state.equipment.bibliography = _.filter(
            state.equipment.bibliography,
            (certifi) => {
                return certifi.id_bibliographic != bibliography.id_bibliographic;
            }
        );
        state.equipment.bibliography.push(bibliography);
    },
};
const actions = {
    // Name Property
    all(context) {
        return new Promise((resolve, reject) => {
            api.all()
                .then(function (res) {
                    context.commit(
                        "MUTATION_SET_ALL_EQUIPMENTS",
                        res.data.data.allEquipment
                    );
                    resolve(res.data.data);
                })
                .catch(function (error) {
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: error,
                        },
                        { root: true }
                    );
                    reject(error);
                });
        });
    },
    index(context) {
        return new Promise((resolve, reject) => {
            api.index()
                .then(function (res) {
                    context.commit("MUTATION_SET_EQUIPMENTS", res.data.data);
                    resolve(res.data.data);
                })
                .catch(function (error) {
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: error,
                        },
                        { root: true }
                    );
                    reject(error);
                });
        });
    },
    show(context, id) {
        return new Promise((resolve, reject) => {
            api.show(id)
                .then(function (res) {
                    context.commit("MUTATION_SET_EQUIPMENT", res.data.data);
                    resolve(res.data.data);
                })
                .catch(function (error) {
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: error,
                        },
                        { root: true }
                    );
                    reject(error);
                });
        });
    },
    store(context, data) {
        return new Promise((resolve, reject) => {
            api.store(data)
                .then((resp) => {
                    context.commit("MUTATION_ADD_EQUIPMENT", resp.data.data);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: resp.data.message,
                        },
                        { root: true }
                    );
                    resolve(resp.data.data);
                })
                .catch((error) => {
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: error,
                        },
                        { root: true }
                    );
                    reject(error);
                });
        });
    },
    update(context, { id, data }) {
        return new Promise((resolve, reject) => {
            api.update(id, data)
                .then((resp) => {
                    context.commit("MUTATION_UPDATE_EQUIPMENT", resp.data.data);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: resp.data.message,
                        },
                        { root: true }
                    );
                    resolve(resp.data.data);
                })
                .catch((error) => {
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: error,
                        },
                        { root: true }
                    );
                    reject(error);
                });
        });
    },
    delete(context, id) {
        return new Promise((resolve, reject) => {
            api.deleted(id)
                .then((resp) => {
                    context.commit("MUTATION_DELETE_EQUIPMENT", id);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: resp.data.message,
                        },
                        { root: true }
                    );
                    resolve(resp.data.code);
                })
                .catch((error) => {
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: error,
                        },
                        { root: true }
                    );
                    reject(error);
                });
        });
    },
    addPhoto(context, { photo, photo_type }) {
        context.commit("MUTATION_ADD_PHOTO", {
            photo: photo,
            photo_type: photo_type,
        });
    },
    addManual(context, { manual, manual_type }) {
        context.commit("MUTATION_ADD_MANUAL", {
            manual: manual,
            manual_type: manual_type,
        });
    },
    addProcedure(context, { procedure, procedure_type }) {
        context.commit("MUTATION_ADD_PROCEDURE", {
            procedure: procedure,
            procedure_type: procedure_type,
        });
    },
    addCertificate(context, certificate) {
        context.commit("MUTATION_ADD_CERTIFICATE", certificate);
    },
    //ADD reference
    addReference(context, reference) {
        context.commit("MUTATION_ADD_REFERENCE", reference);
    },
    addControlLetter(context, controlLetter) {
        context.commit("MUTATION_ADD_CONTROL_LETTER", controlLetter);
    },
    addTraceability(context, traceability) {
        context.commit("MUTATION_ADD_TRACEABILITY", traceability);
    },
    updateManual(context, { manual, manual_type }) {
        context.commit("MUTATION_UPDATE_MANUAL", {
            manual: manual,
            manual_type: manual_type,
        });
    },
    updateProcedure(context, { procedure, procedure_type }) {
        context.commit("MUTATION_UPDATE_PROCEDURE", {
            procedure: procedure,
            procedure_type: procedure_type,
        });
    },
    updateCertificate(context, certificate) {
        context.commit("MUTATION_UPDATE_CERTIFICATE", certificate);
    },
    updateTraceability(context, traceability) {
        context.commit("MUTATION_UPDATE_TRACEABILITY", traceability);
    },
    deletePhoto(context, { id_photo, photo_type }) {
        context.commit("MUTATION_DELETE_PHOTO", {
            id_photo: id_photo,
            photo_type: photo_type,
        });
    },
    deleteManual(context, { id_manual, manual_type }) {
        context.commit("MUTATION_DELETE_MANUAL", {
            id_manual: id_manual,
            manual_type: manual_type,
        });
    },
    deleteProcedure(context, { id_procedure, procedure_type }) {
        context.commit("MUTATION_DELETE_PROCEDURE", {
            id_procedure: id_procedure,
            procedure_type: procedure_type,
        });
    },
    deleteCertificate(context, id_certificate) {
        context.commit("MUTATION_DELETE_CERTIFICATE", id_certificate);
    },
    deleteTraceability(context, id_traceability) {
        context.commit("MUTATION_DELETE_TRACEABILITY", id_traceability);
    },

    addbibliography(context, bibliography) {
        context.commit("MUTATION_ADD_BIBLIOGRAPHY", bibliography);
    },

    deleteBibliography(context, id_bibliographic) {
        context.commit("MUTATION_DELETE_BIBLIOGRAPHY", id_bibliographic);
    },
    updateBibliography(context, bibliography) {
        context.commit("MUTATION_UPDATE_BIBLIOGRAPHY", bibliography);
    },
};

const getters = {};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
};
