const namespaced = true

import api from '../../api/reference-values.js';
import _ from 'lodash';

const state = {
    reference_values : [],
    reference_values_selected: [],
    current_nominal_values: [],
}
const mutations = {
     MUTATION_SET_REFERENCE_VALUES(state, reference_values) {
          state.reference_values = reference_values;
     },
     MUTATION_SET_SELECT_REFERENCE(state, referenceValues) {
          state.reference_values_selected = referenceValues;
     },
     MUTATION_ADD_REFERENCE_VALUE(state, referenceValue){
          state.reference_values.push(referenceValue);
     },
     MUTATION_SET_CURRENT_NOMINAL(state, current){
          state.current_nominal_values=current;
     },
     MUTATION_UPDATE_REFERENCE_VALUE(state, referenceValueUpdate){
          let reference_values = state.reference_values;
          state.reference_values = [];
          _.forEach(reference_values, referenceValue => {
               state.reference_values.push((referenceValue.id_referenceValue == referenceValueUpdate.id_referenceValue)?referenceValueUpdate:referenceValue);
          });
     },
     MUTATION_DELETE_REFERENCE_VALUE(state, id_referenceValue){
          let reference_values = state.reference_values;
          state.reference_values = [];
          _.forEach(reference_values, referenceValue => {
               if(referenceValue.id_referenceValue != id_referenceValue){
                    state.reference_values.push(referenceValue);
               }
          });
     },

}
const actions = {
     selectReferenceValues(context,values){
        context.commit('MUTATION_SET_SELECT_REFERENCE',values)
     },
     setCurrentNominal(context,values){
        context.commit('MUTATION_SET_CURRENT_NOMINAL',values)
     },
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => {
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_REFERENCE_VALUES', res.data.data);
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     show(context, id) {
          return new Promise((resolve, reject) => {
               api.show(id).then(function (res) {
                    resolve(res.data.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          // console.log(data)
          // return data;
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_REFERENCE_VALUE', resp.data.data);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.data);
                }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
                });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
               context.commit('MUTATION_UPDATE_REFERENCE_VALUE', resp.data.data);
               context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
               resolve(resp.data.data);
               }).catch(error => {
               context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
               reject(error);
               });
          });
     },
     delete(context, {id, data}){
          return new Promise((resolve, reject) => {
                api.deleted(id,data).then(resp => {
                    // context.commit('MUTATION_DELETE_REFERENCE_VALUE', id);
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                    resolve(resp.data.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
