import Vue from 'vue';
import Vuex from 'vuex';

/**
 *  Para mantener el estado de vuex-states de modulos especificos.
 */
import createPersistedState from  'vuex-persistedstate';

Vue.use(Vuex);

import configuration        from  './modules/configuration.js';
import notifications        from  './modules/notifications.js';
import authentication       from  './modules/authentication.js';
import abilities            from  './modules/abilities.js';
import files                from  './modules/files.js';
import maps                 from  './modules/maps.js';
import user                 from  './modules/user.js';
import equipments           from './modules/equipments.js';
import photoTypes           from './modules/photo-types.js';
import photos               from './modules/photos.js';
import manualTypes           from './modules/manual-types.js';
import manuals              from './modules/manuals.js';
import procedureTypes       from './modules/procedure-types.js';
import procedures           from './modules/procedures.js';
import certificates         from './modules/certificates.js';
import characteristics      from './modules/characteristics.js';
import products             from './modules/products.js';
import binnacles            from './modules/binnacles.js';
import referenceValues      from './modules/reference-values.js';
import referenceValueDetails from './modules/reference-value-details.js';
import controlLetterTables  from './modules/control-letter-tables.js';
import controlLetterDetails from './modules/control-letter-details.js';
import controlLetters       from './modules/control-letters.js';
import traceabilities       from './modules/traceabilities.js';
import accuracy             from './modules/accuracy.js';
import graphicOne           from './modules/graphicOne.js';
import bibliography         from './modules/bibliography.js';

const dataState = createPersistedState({
  paths: ['configuration']
})

export default new Vuex.Store({
  namespace: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    configuration,
    notifications,
    authentication,
    abilities,
    files,
    maps,
    user,
    equipments,
    photoTypes,
    photos,
    manualTypes,
    manuals,
    procedureTypes,
    procedures,
    certificates,
    characteristics,
    products,
    binnacles,
    referenceValues,
    referenceValueDetails,
    controlLetterTables,
    controlLetterDetails,
    controlLetters,
    traceabilities,
    accuracy,
    graphicOne,
    bibliography
  },
  plugins: [dataState]
})
