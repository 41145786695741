const namespaced = true

const state = {
    themeDark: false,
    tokenUser: '',
    isLoggedIn: false,
}

const mutations = {
    SET_CONFIGURATION(state, input_data) {
        state = input_data;
    },
    SET_THEME_MODE(state, input_data) {
        state.themeDark = input_data;
    },
    SET_CONFIGURATION_SET_TOKEN(state , value){
        state.tokenUser = value;
        state.isLoggedIn = (value)?true:false;
    },
}

const actions = {
    resetConfiguration(context){
        context.commit('SET_CONFIGURATION', {
            themeDark: false,
            tokenUser: '',
            isLoggedIn: false,
        });
    },
    setDarkMode(context, value) {
        if (value) {
            context.commit('SET_THEME_MODE', false);
        } else {
            context.commit('SET_THEME_MODE', true);
        }
    },
    setToken(context, token){
        context.commit('SET_CONFIGURATION_SET_TOKEN', token);
    },
}

const getters = {
    
}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}