const namespaced = true

import api from '../../api/products.js';
import _ from 'lodash';

const state = {
    products: [],
    idProductBack: 0,
    allProducts: 1,
}
const mutations = {
    MUTATION_SET_ID_PRODUCT(state, idProductBack) {
        state.idProductBack = idProductBack;
    },
    MUTATION_SET_ALL_PRODUCTS(state, products) {
        state.allProducts = products;
    },
    MUTATION_SET_PRODUCTS(state, products) {
        state.products = products;
    },
    MUTATION_ADD_PRODUCT(state, product) {
        product.name = product.name_product;
        product.caracteristicts = [];
        state.products.push(product);
    },
    MUTATION_UPDATE_PRODUCT(state, productUpdate) {
        let products = state.products;
        state.products = [];
        _.forEach(products, product => {
            state.products.push((product.id_product == productUpdate.id_product) ? productUpdate : product);
        });
    },
    MUTATION_DELETE_PRODUCT(state, id_product) {
        let products = state.products;
        state.products = [];
        _.forEach(products, product => {
            if (product.id_product != id_product) {
                state.products.push(product);
            }
        });
    },
    MUTATION_ADD_CHARACTERISTIC(state, { id_product, characteristic }) {
        let products = [];
        _.forEach(state.products, product => {
            if (product.id_product == id_product) {
                characteristic.name_characteristic = characteristic.name;
                product.caracteristicts.push({ characteristic: characteristic, id_product: product.id_product });
            }
            products.push(product);
        });
        state.products = products;
    },
    MUTATION_DELETE_CHARACTERISTIC_PRODUCT(state,{id_product,id_characteristic}){
        state.product = id_product;
        const index = state.products.findIndex(
            (product) => product.id_product === id_product
        );
        state.products[index].caracteristicts =
        state.products[index].caracteristicts.filter(c =>  c.characteristic.id_characteristic != id_characteristic);
          //console.log("MDCP: ",state.products[index].caracteristicts);
        // console.log("MDCP: ",state.products[index].characteristics.filter(c =>  c.characteristic.id_characteristic != id_characteristic));
      //  if (index !== -1) state.chapters.splice(index, 1, chapter);

    }

}
const actions = {
    // Name Property
    all(context) {
        return new Promise((resolve, reject) => {
            api.all().then(function (res) {
                context.commit('MUTATION_SET_ALL_PRODUCTS', res.data.data.allProducts);
                resolve(res.data.data);
            }).catch(function (error) {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error }, { root: true });
                reject(error);
            });
        })
    },
    index(context) {
        return new Promise((resolve, reject) => {
            api.index().then(function (res) {
                context.commit('MUTATION_SET_PRODUCTS', res.data.data);
                resolve(res.data.data);
            }).catch(function (error) {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error }, { root: true });
                reject(error);
            });
        })
    },
    show(context, id) {
        return new Promise((resolve, reject) => {
            api.show(id).then(function (res) {
                resolve(res.data.data);
            }).catch(function (error) {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error }, { root: true });
                reject(error);
            });
        })
    },
    store(context, data) {
        return new Promise((resolve, reject) => {
            api.store(data).then(resp => {
                context.commit('MUTATION_ADD_PRODUCT', resp.data.data);
                context.commit('MUTATION_SET_ID_PRODUCT', resp.data.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': resp.data.message }, { root: true });
                resolve(resp.data.data);
            }).catch(error => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error }, { root: true });
                reject(error);
            });
        });
    },
    update(context, { id, data }) {
        return new Promise((resolve, reject) => {
            api.update(id, data).then(resp => {
                context.commit('MUTATION_UPDATE_PRODUCT', data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': resp.data.message }, { root: true });
                resolve(resp.data.data);
            }).catch(error => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error }, { root: true });
                reject(error);
            });
        });
    },
    delete(context, id) {
        return new Promise((resolve, reject) => {
            api.deleted(id).then(resp => {
                context.commit('MUTATION_DELETE_PRODUCT', id);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': resp.data.message }, { root: true });
                resolve(resp.data.code);
            }).catch(error => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error }, { root: true });
                reject(error);
            });
        });
    },
    addDetail(context, { id_product, characteristic }) {
        return new Promise((resolve, reject) => {
            api.addDetail(id_product, characteristic.id_characteristic).then(resp => {
                context.commit('MUTATION_ADD_CHARACTERISTIC', { id_product: id_product, characteristic: characteristic });
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': resp.data.message }, { root: true });
                resolve(resp.data.code);
            }).catch(error => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error }, { root: true });
                reject(error);
            });
        });
    },
    resetIdProduct(context) {
        context.commit('MUTATION_SET_ID_PRODUCT', 0);
    },

    deleteDetail(context, {id_product,id_characteristic}) {
        return new Promise((resolve, reject) => {
            api.deleteDetail(id_product,id_characteristic).then(resp => {
                context.commit('MUTATION_DELETE_CHARACTERISTIC_PRODUCT', { id_product, id_characteristic });
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': resp.data.message }, { root: true });
                resolve(resp.data.code);
            }).catch(error => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error }, { root: true });
                reject(error);
            });
        });
    },
}
const getters = {
}
export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}
